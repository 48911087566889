export const liqaConfig = {
  // "easy" | "medium" | "hard"
  rejectionСomplexity: "easy",
  // 'png' | 'jpeg-100' | 'jpeg-75' | 'jpeg-50'
  imageFormat: "jpeg-100",
  	// 'far' | 'close'
	facePosition: 'close',
  showIlluminationPoints: true,
  showOverlay: false,
  includeBeardArea: true,
  includeHairsArea: true,
  autoSelfieCapture: true,
  predictLandmarks: false,
};
