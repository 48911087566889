<template>
    
  <div id="app" >

    <div ref="myModal" class="modal">
      <img ref="imgSelfie" />
      <canvas
        ref="canSelfie"
        style="position: absolute; top: 0px; left: 0px"
      ></canvas>
      <div class="btn-holder" v-if="!liqaErrors.qualityIsOkay">
        <button v-on:click="playLiqa()" type="button" class="btn" style="width: 30%; margin-right: 0.5rem;">
          <div class="btn-inner">
            RETAKE
          </div>
        </button>
        <button v-on:click="exitLiqa" type="button" class="btn gray">
          <div class="btn-inner">
            CONFIRM
          </div>
        </button>
      </div>
      <div class="btn-holder" v-else>
        <button v-on:click="playLiqa()" type="button" class="btn gray" style="width: 30%; margin-right: 0.5rem;">
          <div class="btn-inner">
            RETAKE
          </div>
        </button>
        <button v-on:click="exitLiqa" type="button" class="btn">
          <div class="btn-inner">
            CONFIRM
          </div>
        </button>
      </div>
    </div>
    <!--
      <div ref="photoQuality" class="alert alert-warning" role="alert">Please, use mobile</div>
    -->
    



    <!-- Loader bars -->
    <div ref="loaderBars" id="loaderBars">
      <div class="loadOuterBar">
        <div ref="loadBar" class="loadBar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div class="loadOuterTextBar">
        <div ref="loadTextBar" class="loadTextBar">Loading AI models…</div>
      </div>
      <div class="loadOuterHTextBar">
        <div ref="loadHTextBar" class="loadHTextBar"></div>
      </div>
    </div>


    <!-- Main container with canvas (camera stream), then send it (link) to LIQA SDK -->
    <div id="canMainHolder">
      <div ref="canMain" id="canMain">
        <video 
          id="liqaVideo" 
          style="-webkit-transform: scaleX(-1); transform: scaleX(-1); display: none;" 
          playsinline 
          controls
        >
        </video>
        <canvas id="liqaCanvas"></canvas>

        <!-- UI START -->
        <div id="uiElemsTop" :class="{ hair: liqaConfig.facePosition == 'far' }">
          <div id="uiElems">
            <template v-if="!liqaErrors.qualityIsOkay && liqaStarted && !liqaExited">
              <svg class="face front" viewBox="0 0 1016 1430" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M507.998 9.40814C232.637 9.40814 9.41294 232.519 9.41294 507.739V922.261C9.41294 1197.48 232.637 1420.59 507.998 1420.59C783.363 1420.59 1006.59 1197.48 1006.59 922.261C1006.59 919.663 1008.69 917.557 1011.29 917.557C1013.89 917.557 1016 919.663 1016 922.261C1016 1202.68 788.561 1430 507.998 1430C227.439 1430 0 1202.68 0 922.261V507.739C0 227.323 227.439 0 507.998 0C788.561 0 1016 227.323 1016 507.739V752.633C1016 755.231 1013.89 757.337 1011.29 757.337C1008.69 757.337 1006.59 755.231 1006.59 752.633V507.739C1006.59 232.519 783.363 9.40814 507.998 9.40814ZM10.5588 561.53H1005.44V570.938H500.999V724.164H491.586V570.938H10.5588V561.53Z"
									fill="white"
								/>
              </svg>
              <svg v-if="liqaConfig.facePosition == 'far'" width="331" height="467" viewBox="0 0 331 467" class="face hair" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M313 251V157C313 70.8436 243.156 1 157 1C70.8436 1 1 70.8436 1 157V388C1 416.414 8.59675 443.054 21.87 466M313 287V388C313 416.414 305.403 443.054 292.13 466" stroke="white" stroke-linecap="round"/>
                <path d="M299.44 280.86L300.532 280.23L298.601 276.886L303.037 274.325L304.967 277.669L306.059 277.038L301.62 269.349L300.528 269.979L302.439 273.289L298.003 275.85L296.092 272.54L295 273.171L299.44 280.86Z" fill="white"/>
                <path d="M308.889 275.404L310.015 274.754L309.688 271.926L312.964 270.034L315.262 271.725L316.399 271.069L308.582 265.329L307.782 265.791L308.889 275.404ZM309.544 270.688L309.127 267.236L311.942 269.304L309.544 270.688Z" fill="white"/>
                <path d="M319.259 269.418L320.362 268.781L315.923 261.091L314.819 261.728L319.259 269.418Z" fill="white"/>
                <path d="M322.868 261.525L321.483 259.127L322.395 258.6C323.431 258.002 324.115 258.043 324.615 258.909C325.083 259.72 324.804 260.407 323.78 260.998L322.868 261.525ZM324.28 266.519L325.383 265.882L323.401 262.448L323.727 262.259C324.594 261.759 325.258 262.051 326.382 262.618L328.862 263.873L330.191 263.106L326.697 261.371C326.235 261.142 325.845 260.962 325.404 260.976C326.224 260.083 326.318 259.232 325.74 258.23C324.973 256.902 323.668 256.619 322.092 257.529L319.841 258.829L324.28 266.519Z" fill="white"/>
              </svg>
            </template>

            <img v-if="!liqaErrors.qualityIsOkay && liqaStarted && !liqaExited && !liqaErrors.faceDetection" class="where" src="../public/quality-face/where.svg" alt="">
            <div v-if="!liqaErrors.qualityIsOkay && liqaStarted && !liqaExited && liqaErrors.faceDetection">

              <img v-if="liqaErrors.facePosition == 'too far'" class="plane_down" src="../public/quality-face/plane_down.svg" alt="">
              <img v-if="liqaErrors.facePosition == 'too close'" class="plane_top" src="../public/quality-face/plane_top.svg" alt="">
              <img v-if="liqaErrors.facePosition == 'out of frame'" class="ninja" src="../public/quality-face/ninja.svg" alt="">
              <div v-if="liqaErrors.facePosition == 'ok'">
                <img class="face_string" src="../public/quality-face/face_string.svg" alt="">
              </div>

              <div v-if="(liqaErrors.faceRotation == 'turn left') || (liqaErrors.faceRotation == 'turn right')">
                <img v-if="liqaErrors.faceRotation == 'turn left'" class="turn_face_left" src="../public/quality-face/turn_face_left.svg" alt="">
                <img v-if="liqaErrors.faceRotation == 'turn right'" class="turn_face_right" src="../public/quality-face/turn_face_right.svg" alt="">
              </div>
              <div v-else >
                <img v-if="liqaErrors.faceRotation == 'incline left'" class="roll_face_left" src="../public/quality-face/roll_face_left.svg" alt="">
                <img v-if="liqaErrors.faceRotation == 'incline right'" class="roll_face_right" src="../public/quality-face/roll_face_right.svg" alt="">
                <div v-if="liqaErrors.faceRotation != 'incline left' && liqaErrors.faceRotation != 'incline right'">
                  <img v-if="liqaErrors.faceRotation == 'incline up'" class="pitch_t" src="../public/quality-face/pitch_t.svg" alt="">
                  <img v-if="liqaErrors.faceRotation == 'incline down'" class="pitch_b" src="../public/quality-face/pitch_b.svg" alt="">
                </div>
              </div>
            </div>

            <div class="text-element" v-if="!liqaErrors.qualityIsOkay && liqaStarted && !liqaExited && textWarning">
              {{textWarning}}
            </div>

          </div>
        </div>
        <!-- UI END -->
      </div>
    </div>

    <!-- ICON BAR START -->
    <div v-if="liqaLoaded && !liqaExited" class="bottom-elements">
      <div class="bottom-elements__element">
        <img v-if="liqaErrors.facePosition != 'out of frame' && liqaErrors.faceDetection" src="../public/quality-face/check.svg" alt="">
        <img v-if="liqaErrors.facePosition == 'out of frame' || !liqaErrors.faceDetection" src="../public/quality-face/cross.svg" alt="">
        Position
      </div>
      <div class="bottom-elements__element">
        <img v-if="liqaErrors.faceRotation == 'ok'" src="../public/quality-face/check.svg" alt="">
        <img v-if="liqaErrors.faceRotation != 'ok'" src="../public/quality-face/cross.svg" alt="">
        Angle
      </div>
      <div class="bottom-elements__element">
        <img v-if="liqaErrors.facePosition != 'too far' && liqaErrors.facePosition != 'too close'" src="../public/quality-face/check.svg" alt="">
        <img v-if="liqaErrors.facePosition == 'too far' || liqaErrors.facePosition == 'too close'" src="../public/quality-face/cross.svg" alt="">
        Size
      </div>
      <div class="bottom-elements__element">
        <img v-if="liqaErrors.faceIllumination == 'ok'" src="../public/quality-face/check.svg" alt="">
        <img v-if="liqaErrors.faceIllumination != 'ok'" src="../public/quality-face/cross.svg" alt="">
        Light
      </div>
    </div>
    <!-- ICON BAR END -->

    <div v-if="(windowMode == 'lib') && !liqaExited" ref="buttons" class="btn-holder">
      <button ref="startBut" v-on:click="startLiqa" type="button" class="btn">
        <div class="btn-inner">
          <img v-if="status.load" src="../public/loading.gif" class="btn-load" />
          {{ status.text }}
        </div>
      </button>
      <button ref="selfieBut" :disabled="!liqaErrors.qualityIsOkay" v-on:click="drawSelfie" type="button" class="btn">
        <div class="btn-inner">
          TAKE PHOTO
        </div>
      </button>
    </div>
    <div v-if="(windowMode != 'lib') && !liqaExited" ref="buttons" class="btn-holder">
      <button ref="selfieBut" v-on:click="drawSelfie" :disabled="!liqaErrors.qualityIsOkay" type="button" class="btn" style="visibility: hidden;" >
        <div class="btn-inner">
          TAKE PHOTO
        </div>
      </button>
    </div>

  </div>
</template>

<script>


import { Liqa } from "@liqa/e-commerce";
import { liqaConfig } from "./config";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


export default {
  name: "app",
  data() {
    return {
      width: 640,
      height: 480,
      liqa: null,
      photo: "",
      liqaConfig: liqaConfig,

      liqaErrors: {
        qualityIsOkay: false,
        faceDetection: false,
        facePosition: 'too far',
        faceRotation: '',
        faceIllumination: ''
      },
      liqaStarted: false,
      liqaExited: false,
      textWarning: null,

      status: { text: "Start", textS: "Take Photo", color: "#DC3849", load: false },
      statusDict: {
        '10': 'Initializing AI models…',
        '80': 'Initializing modules…',
        '90': 'Starting camera…',
        '100': 'Loading finished successfully',
        '-90': 'Please, check camera availability and reload the page.',
      },

      onDestroy: null,
      liqaLoading: false,
      liqaLoaded: false,

      globalLiqaState: "", // "", loaded & inited, playing, stopped, exited
      windowMode: "lib", // lib, iframe, webview

      events: { value: 0, msg: "Loading AI models…" },

      isMobile: () => {
        return (this.isAndroid() || this.isiOS()) && !this.isLinux();
      },
      isAndroid: () => {
        return /Android/i.test(window.navigator.platform) || /Linux/i.test(window.navigator.platform);
      },
      isiOS: () => {
        const apple = /iPhone/i.test(window.navigator.platform) || /iPod/i.test(window.navigator.platform) || /iPad/i.test(window.navigator.platform);
        return apple && (window.innerWidth < 480);
      },
      isLinux: () => {
        return /Linux/i.test(window.navigator.platform) && !(/Android/i.test(window.navigator.userAgent));
      },
      isIPad: () => {
        return !this.isMobile() && (window.orientation !== undefined);
      },
      isIPadPortrait: () => {
        return this.isIPad() && ((window.orientation == 0) || (Math.abs(window.orientation) == 180));
      }
    }
  },

  mounted() {

    this.container = this.$refs.canMain;
    this.onDestroy = new Subject();
    this.globalLiqaState = "";

    this.windowMode = "iframe";

    this.liqa = new Liqa();

    this.width = window.innerWidth;
    this.height = 0.87 * window.innerHeight;
      
    /**
     * IFRAME
     * Set up listener to receive commands from outside (client)
     */
    setTimeout(() => {

      window.onmessage = (e) => {
        // set position    
        if(e.data?.Parameters?.hasOwnProperty('allowed_distance')){
          this.liqaConfig.facePosition = e.data.Parameters.allowed_distance == 'far' ? 'far' : 'close';
        }
        // set styles
        if(e.data?.hasOwnProperty('Style')){
            const styles = Object.keys(e.data?.Style);
            styles.forEach(el=>{
              document.documentElement.style.setProperty('--'+el, e.data.Style[el]);
            })
        }
        // set custom styles        
        if(e.data?.hasOwnProperty('customStylesUrl')){
          this.appendcustomStyles(e.data['customStylesUrl']);
        }
        // set state 
        if (e.data?.hasOwnProperty('Start')) {
          this.$refs.loaderBars.style.visibility = "visible";

          if (
              ((this.globalLiqaState == "loaded & inited") || 
              (this.globalLiqaState == "stopped")) && 
              this.liqa
              ) {
            this.playLiqa();
          } else {
            this.loadLiqa(); 
          }
        } else if (e.data?.hasOwnProperty('Stop')) {
          this.stopLiqa();
        } else if (e.data?.hasOwnProperty('Exit')) {
          this.exitLiqa();
        }
      }
    }, 200);
  },

  methods: {
    appendcustomStyles(href){
      let file = document.createElement('link');
      file.rel = 'stylesheet';
      file.href = href;
      try{
        document.head.appendChild(file)
      } catch(e) {

      }
    },

    startLiqa() {
      if (((this.globalLiqaState == "playing") || (this.globalLiqaState == "loaded & inited") || (this.globalLiqaState == "stopped")) && this.liqa) {
        if (this.globalLiqaState == "stopped") {
          this.playLiqa();
        } else {
          this.stopLiqa();
        }
      } else {
        this.loadLiqa();
      }
    },

    playLiqa(side = "front") {
      this.$refs.myModal.style.display = "none";

      this.liqa.play(side).then(() => {
        this.liqaLoaded = true;
        this.$refs.selfieBut.style.visibility = "visible";
        try {
          try {
            const str = JSON.stringify({status: side});
            window.parent.window.ReactNativeWebView.postMessage(str);
          } catch (e) {
            window.parent.postMessage({status: side}, '*');
          }
        } catch (e) {}
      }).catch((e) => {
        if (e == "Devices not found") {
          this.events.value = -90;
          this.events.msg = `Loading failed. Error: ${e}.`;
          this.changeStatusBar();
          try {
            const str = JSON.stringify({status: "305"});
            window.parent.window.ReactNativeWebView.postMessage(str);
          } catch (e) {
            window.parent.postMessage({status: "305"}, '*');
          }
        } else {
          this.events.value = -80;
          this.events.msg = `Loading failed. Error: ${e}`;
          this.changeStatusBar();
          try {
            const str = JSON.stringify({status: "307"});
            window.parent.window.ReactNativeWebView.postMessage(str);
          } catch (e) {
            window.parent.postMessage({status: `307:${e}`}, '*');
          }
        }
        
      });
      this.globalLiqaState = "playing";

      this.status.text = "Stop";
      this.status.load = false;

    },

    stopLiqa() {
      this.liqa.stop();
      this.globalLiqaState = "stopped";

      this.status.text = "Play";
      this.status.load = false;
    },

    unMountLiqa() {
      this.liqa.unmount();
    },

    exitLiqa() {
      try {
        const str = JSON.stringify({photo: this.photo});
        window.parent.window.ReactNativeWebView.postMessage(str);
      } catch (e) {
        window.parent.postMessage({photo: this.photo}, '*');
      }
      this.$refs.myModal.style.display = "none";
      this.liqaExited = true;
      this.onDestroy.next(true);
      this.liqa.exit();
      this.liqa = null;
      this.globalLiqaState = "exited";
    },

    changeStatusBar() {
      if (this.events.value < 0) {
        this.$refs.loadBar.style.width = `100%`;
        this.$refs.loadBar.style.backgroundColor = `red`;
      } else {
        this.$refs.loadBar.style.width = `${this.events.value}%`;
      }
      this.$refs.loadTextBar.innerHTML = this.events.msg;
      this.$refs.loadHTextBar.innerHTML = '';
      if (this.events.value == -90) {
        this.$refs.loadHTextBar.innerHTML = this.statusDict['-90'];
      }

      if (this.events.value == 100) {
        setTimeout(() => {
          this.$refs.loaderBars.style.visibility = "hidden";
        }, 1000);
      }
    },

    async loadLiqa() {
      this.status.text = "";
      this.status.load = true;
      this.liqaLoading = true;

      const t1 = performance.now();

      this.liqa.events$
        .pipe(takeUntil(this.onDestroy))
        .subscribe((status) => {
          if(this.statusDict.hasOwnProperty(status)){
            this.events.value = status;
            this.events.msg = this.statusDict[status];
          } else {
            // TODO wrong status
          }
          // else if (status == -10) {
          //   this.events.value = status;
          //   this.events.msg = "Loading failed";
          // } else if (status == -80) {
          //   this.events.value = status;
          //   this.events.msg = "Error occurred during LIQA start: Devices not found";
          // } else if (status == -90) {
          //   this.events.value = status;
          //   this.events.msg = "Error occurred during LIQA work";
          // }
          this.changeStatusBar();
        });

      this.liqa
        .load({ staticPath: "./", compileModels: false, useLocalModel: false })
        .then(() => {
          // console.log(
          //   `Liqa loaded: ${
          //     Math.round((10 * (performance.now() - t1)) / 1000) / 10
          //   } seconds`
          // );
          try {
            const str = JSON.stringify({status: "loaded"});
            window.parent.window.ReactNativeWebView.postMessage(str);
          } catch (e) {
            window.parent.postMessage({status: "loaded"}, '*');
          }
          this.initLiqa();
          this.globalLiqaState = "loaded & inited";
          this.playLiqa();

          this.status.text = "Stop";
          this.status.load = false;

        }).catch((e) => {
          this.events.value = -10;
          this.events.msg = "Loading failed";
          this.changeStatusBar();
          try {
            const str = JSON.stringify({status: `301:${e}`});
            window.parent.window.ReactNativeWebView.postMessage(str);
          } catch (e) {
            window.parent.postMessage({status: `301:${e}`}, '*');
          }
          console.error(`Load method failure: ${e}`);
        });
    },

    async initLiqa() {
      this.liqa.init({
        container: this.container,
        width: this.width,
        height: this.height,
        config: this.liqaConfig,
      });

      /**
       * API emitters for receiving data from LIQA
       * And if it's a photo, send it to client
       */
      this.liqa.selfieImage$
        .pipe(takeUntil(this.onDestroy))
        .subscribe((b64selfie) => {
          /* get output selfie photo and draw it or send it */

          if (this.windowMode == "lib") {
            this.$refs.myModal.style.display = "block";
            let imageFoo = this.$refs.imgSelfie;
            imageFoo.width = window.innerWidth;
            imageFoo.src = b64selfie;
          } else if (this.windowMode == "webview") {
            window.parent.window.ReactNativeWebView.postMessage(b64selfie);
          } else if (this.windowMode == "iframe") {
            this.$refs.myModal.style.display = "block";

            let imageFoo = this.$refs.imgSelfie;
            imageFoo.width = window.innerWidth;
            imageFoo.src = b64selfie;
            this.photo = b64selfie;
          }
          // this.stopLiqa();
        });

      this.liqa.qualityStatus$
        .pipe(takeUntil(this.onDestroy))
        .subscribe((options) => {
          /* Receiving outputs */
          this.liqaStarted = true;
          this.liqaErrors = {...options};
          /* page statuses will be updated because `this.liqaErrors` is bound to the template */
        });

      // this.liqa.selfieLandmarks$
      //   .pipe(takeUntil(this.onDestroy))
      //   .subscribe((points) => {
      //     /* draw landmarks over the photo to check aspect ratio and dimension */

      //     let canvas = this.$refs.canSelfie;
      //     canvas.width = this.$refs.imgSelfie.width;

      //     canvas.height =
      //       this.$refs.imgSelfie.width *
      //       (this.liqa.videoSize[1] / this.liqa.videoSize[0]);

      //     const ctx = canvas.getContext("2d");
      //     ctx.restore();
      //     ctx.clearRect(0, 0, canvas.width, canvas.height);
      //     ctx.globalAlpha = 0.7;
      //     ctx.fillStyle = "red";

      //     for (let i = 0; i < points.length; i++) {
      //       let x = points[i][0] * canvas.width;
      //       let y = points[i][1] * canvas.height;
      //       ctx.beginPath();
      //       ctx.arc(x, y, 2, 0, 2 * Math.PI);
      //       ctx.fill();
      //     }
      //   });


      this.liqa.qualityFeatures$
        .pipe(takeUntil(this.onDestroy))
        .subscribe((options) => {
          /* Receiving outputs */
          // Parse detailed outputs
        });
    },

    async drawSelfie() {
      this.liqa.captureSelfie().catch((e) => {
        console.error("Selfie Exception:", e);
      });
    },

    async parseOutputs(options) {

      if (!this.liqaErrors.faceDetection) {
        this.textWarning = 'No face found inside central area';
      } else if (this.liqaErrors.facePosition === 'too far') {
        this.textWarning = 'Bring camera closer';
      } else if (this.liqaErrors.facePosition === 'too close') {
        this.textWarning = 'Move camera back a little';
      } else if (this.liqaErrors.facePosition === 'out of frame') {
        this.textWarning = 'Parts of the face are covered';
      } else if (this.liqaErrors.faceRotation !== 'ok') {
        if (this.liqaErrors.faceRotation === 'turn left') {
          this.textWarning = 'Turn face to the left';
        } else if (this.liqaErrors.faceRotation === 'turn right') {
          this.textWarning = 'Turn face to the right';
        } else {
          this.textWarning = 'Face is tilted. Pose strighter a little';
        }
      } else if (this.liqaErrors.faceIllumination === 'too contrast') {
        this.textWarning = 'Too much contrast. Ajust lighting conditions';
      } else if (this.liqaErrors.faceIllumination === 'too dark') {
        this.textWarning = 'Too dark. Blue dots should disappear';
      } else if (this.liqaErrors.faceIllumination === 'too light') {
        this.textWarning = 'Too bright. Red dots should disappear';
      } else {
        this.textWarning = null;
      }
    }
  },
}
</script>

<style>
@import './App.css';
</style>
